button:focus {
    outline: none;
    outline: none;
}
.table {
    margin-bottom: auto;
}

.header-box-table {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 0 15px 15px;
}

.header-table {
    font-family: "Helvetica 65";
}

.header-page {
    margin: 0 15px 15px;
    font-family: "Helvetica 65";
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.header-page h1,
.header-table h1 {
    display: inline-block;
    font-size: 22px;
    margin: 0;
    color: #1c263f;
}

.header-page .p {
    display: inline-block;
    margin-left: 5px;
    font-size: 18px;
    color: #5e6671;
}

body {
    font-size: 14px;
    font-family: "helvetica neue";
}

.label-bootstrap {
    font-size: 14px;
    padding: 0 0 0 10px;
    align-self: flex-start;
    margin: 0;
    color: #6b7385;
    font-weight: 500;
    font-family: "helvetica neue";
}

.pre-wrap {
    white-space: pre-wrap !important;
}
